<template>
  <div class="industry">
    <div class="industry-second">
      <div class="industry-second-up">
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          @submit="handleSearch"
        >
          <a-row class="ant-advanced-search-form-top" :gutter="24">
            <a-col :span="4">
              <!--<a-form-item label="行政区划：">-->
              <!--<a-select-->
              <!--mode="multiple"-->
              <!--style="width: 100%"-->
              <!--placeholder="请选择"-->
              <!--@change="handleChangeArea"-->
              <!--v-model="param.selectedAreaId"-->
              <!--&gt;-->
              <!--<a-select-option-->
              <!--:value="item.id"-->
              <!--v-for="(item, index) in areas"-->
              <!--:key="index"-->
              <!--&gt;-->
              <!--{{ item.name }}-->
              <!--</a-select-option>-->
              <!--</a-select>-->
              <!--</a-form-item>-->
              <a-form-item label="行政区划：">
                <a-cascader
                  :options="options"
                  @change="areaChange"
                  placeholder="请选择"
                  expandTrigger="hover"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="时间：">
                <a-select
                  mode="single"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.reportYear"
                  @change="handleChangeYear"
                >
                  <a-select-option v-for="i in year" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item label="">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.season"
                  @change="handleChangeSeason"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in seasons"
                    :key="index"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="集中供水工程：">
                <a-select
                  mode="single"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChangeNatManageUnit"
                  v-model="param.natManageUnit"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in natManageUnits"
                    :key="index"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" :style="{ textAlign: 'right' }">
              <a-button type="primary" html-type="submit">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                重置
              </a-button>
            </a-col>
          </a-row>
          <a-row> </a-row>
        </a-form>
      </div>
      <div class="industry-second-down">
        <div class="industry-second-ud">
          <div class="industry-second-ud-l">
            <div
              class="industry-second-udone"
              :class="contrastIndex == index ? 'progress-tabone-select' : ''"
              v-for="(item, index) in tabLista"
              :key="index"
              @click="checkContrast(index)"
            >
              {{ item }}
              <div></div>
            </div>
          </div>
          <div class="industry-second-down-top-right flex align-center ">
            <a-button type="primary">
              导出
            </a-button>
          </div>
        </div>
        <!--<div class="industry-second-down-top flex align-center justify-between">-->
        <!--<div class="industry-second-down-top-left"></div>-->
        <!--<div class="industry-second-down-top-right flex align-center ">-->
        <!--<a-button type="primary">-->
        <!--导出-->
        <!--</a-button>-->
        <!--</div>-->
        <!--</div>-->
        <!-- 表格 -->
        <!-- 指标超标分析 -->
        <div id="ztfx">
          <div>
            <a-table
              :columns="columns"
              :data-source="datas"
              bordered
              size="middle"
              :pagination="false"
              :scroll="{ x: 'calc(300px + 20%)', y: 1000 }"
            />
          </div>
        </div>
        <!-- 水质超标统计 -->
        <div id="qydb">
          <div>
            <a-table
              :columns="columns1"
              :data-source="datas1"
              bordered
              size="middle"
              :scroll="{ x: 'calc(600px + 50%)', y: 1000 }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 表格1数据
const columns = [
  {
    title: "序号",
    dataIndex: "key",
    key: "key",
    width: 80,
    align: "center"
  },
  {
    title: "市／县名称",
    dataIndex: "name",
    key: "name",
    width: 80,
    align: "center"
  },
  {
    title: "水样总数（件）",
    dataIndex: "allpopulation",
    key: "allpopulation",
    width: 80,
    align: "center"
  },
  {
    title: "问题水样（件）",
    dataIndex: "allnum",
    key: "allnum",
    width: 80,
    align: "center"
  },
  {
    title: "问题水样占比（%）",
    dataIndex: "percent1",
    key: "percent1",
    width: 90,
    align: "center"
  },
  {
    title: "检测覆盖农村供水总人口数（万人）",
    dataIndex: "num1",
    key: "num1",
    width: 70,
    align: "center"
  },
  {
    title: "问题水样覆盖人口数（万人）",
    dataIndex: "num2",
    key: "num2",
    width: 80,
    align: "center"
  },
  {
    title: "问题水样覆盖人口占比（%）",
    dataIndex: "num3",
    key: "num3",
    width: 80,
    align: "center"
  },
  {
    title: "检测覆盖工程总数（处）",
    dataIndex: "num4",
    key: "num4",
    width: 80,
    align: "center"
  },
  {
    title: "超标工程处数（处）",
    dataIndex: "num5",
    key: "num5",
    width: 80,
    align: "center"
  },
  {
    title: "超标工程占比（%）",
    dataIndex: "num6",
    key: "num6",
    width: 80,
    align: "center"
  }
];
// 表格1
const datas = [];
// for (let i = 0; i < 14; i++) {
//   datas.push({
//     key: i,
//     name: "John Brown",
//     allpopulation:"11",
//     allnum:"22",
//     percent1:12,
//     num1: 1,
//     num2: 1,
//     num3: 1,
//     num4: 1,
//     num5: 1,
//     num6: 1,
//   });
// }

const columns1 = [
  {
    title: "序号",
    dataIndex: "key",
    key: "key",
    width: 80,
    align: "center"
  },
  {
    title: "市／县名称",
    dataIndex: "name",
    key: "name",
    width: 150,
    align: "center"
  },
  {
    title: "超标指标名称",
    dataIndex: "allpopulation",
    key: "allpopulation",
    width: 120,
    align: "center"
  },
  {
    title: "超标处数(处)",
    dataIndex: "allnum",
    key: "allnum",
    width: 80,
    align: "center"
  },
  {
    title: "超标供水工程占比（%）",
    dataIndex: "percent1",
    key: "percent1",
    width: 150,
    align: "center"
  },
  {
    title: "超标覆盖农村供水总人口数（万人）",
    dataIndex: "num1",
    key: "num1",
    width: 120,
    align: "center"
  },
  {
    title: "超标覆盖农村供水总人口占比（%）",
    dataIndex: "num2",
    key: "num2",
    width: 120,
    align: "center"
  },
  {
    title: "问题水样（件）",
    dataIndex: "num3",
    key: "num3",
    width: 80,
    align: "center"
  },
  {
    title: "问题水样占比（%）",
    dataIndex: "num4",
    key: "num4",
    width: 120,
    align: "center"
  },
  {
    title: "最大值",
    children: [
      {
        title: "值",
        dataIndex: "num5",
        key: "num5",
        width: 100,
        align: "center"
      },
      {
        title: "供水工程所在行政区域",
        dataIndex: "num6",
        key: "num6",
        width: 100,
        align: "center"
      },
      {
        title: "供水工程名称",
        dataIndex: "num7",
        key: "num7",
        width: 100,
        align: "center"
      }
    ]
  },
  {
    title: "最小值",
    children: [
      {
        title: "值",
        dataIndex: "num8",
        key: "num8",
        width: 100,
        align: "center"
      },
      {
        title: "供水工程所在行政区域",
        dataIndex: "num9",
        key: "num9",
        width: 100,
        align: "center"
      },
      {
        title: "供水工程名称",
        dataIndex: "num10",
        key: "num10",
        width: 100,
        align: "center"
      }
    ]
  }
];
// 表格2
const datas1 = [];
// for (let i = 0; i < 14; i++) {
//   datas1.push({
//     key: i,
//     name: "John Brown",
//     allpopulation:"11",
//     allnum:"22",
//     percent1:12,
//     num1: 1,
//     num2: 1,
//     num3: 1,
//     num4: 1,
//     num5: 1,
//     num6: 1,
//     num7: 1,
//     num8: 1,
//     num9: 1,
//     num10: 1,
//
//   });
// }

import { getAreas, getDefaultArea } from "@/api/area/index";
import { baseMenuList } from "@/api/industry/index";
import {
  getConsumBadQuality,
  getAreaBadQualityTable,
  getAreaBadDetectTable
} from "@/api/water/analysis";
import { getAreaTreeList } from "@/api/basic/common";
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      tabLista: ["超标统计分析", "超标指标分析"], //指标超标分析/水质超标统计tab
      contrastIndex: 0, //切换指标超标分析/水质超标统计index
      // 筛选条件
      areas: [], //测试数据/行政区划
      year: [], //测试数据/年度
      sampleTypes: [],
      seasons: [],
      natManageUnits: [],
      // 筛选参数
      param: {
        defaultAreaId: undefined,
        selectedAreaId: undefined,
        areaId: undefined,
        reportYear: undefined,
        sampleType: [],
        season: [],
        natManageUnit: undefined
      },
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      userAreaId: {
        id: 1
      },
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "2020",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      },
      columns,
      datas,
      columns1,
      datas1,
      current: 1,
      list: []
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
    });
    this.getAreaList();
  },
  mounted() {
    document.getElementById("qydb").style.display = "none";
  },
  methods: {
    areaChange(value) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaId = value[value.length - 1];
      console.log(value);
    },
    async initData() {
      // 获取筛选条件
      await baseMenuList({
        menu: "水样类型"
      }).then(res => {
        this.sampleTypes = res.data.list;
      });
      await baseMenuList({
        menu: "供水季节"
      }).then(res => {
        this.seasons = res.data.list;
        // this.param.season = 1;
      });
      await baseMenuList({
        menu: "工程运行管理单位性质"
      }).then(res => {
        this.natManageUnits = res.data.list;
      });
      // 查询相关数据
      this.getDatas();
    },
    getAreaList() {
      let data = getDefaultArea();
      console.log(data);
      this.param.defaultAreaId = data.id;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (!this.param.areaId) {
        this.param.areaId = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.areaId
      }).then(res => {
        if (res.code == 200) {
          this.areas = res.data.list;
        }
        this.initData();
      });
    },
    // 切换指标超标分析/水质超标统计
    checkContrast(index) {
      this.contrastIndex = index;
      if (index == 0) {
        document.getElementById("ztfx").style.display = "block";
        document.getElementById("qydb").style.display = "none";
      } else {
        document.getElementById("qydb").style.display = "block";
        document.getElementById("ztfx").style.display = "none";
      }
    },
    //跳转
    router(x) {
      this.$router.push({
        path: x
      });
    },
    activefn(index, active) {
      this.columns[index].active = active;
    },

    //查询
    handleSearch(e) {
      e.preventDefault();
      this.getDatas();
    },
    //清除
    handleReset() {
      this.param.areaId = this.param.defaultAreaId;
      this.param.selectedAreaId = undefined;
      this.param.reportYear = 2020;
      this.param.sampleType = [];
      this.param.season = undefined;
      this.param.natManageUnit = undefined;
      // this.form.reset()
      this.getDatas();
    },
    //多选运行管理单位
    handleChange(value) {
      console.log(`selected ${value}`);
      this.param.reportYear = value;
    },
    // 统计数据
    getDatas() {
      // 指标超标分析
      this.getAreaBadQualities();
      // 水质超标统计
      this.getAreaBadDetects();
    },
    // 指标超标分析
    getAreaBadQualities() {
      getAreaBadQualityTable({
        areaIds: this.param.areaId,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleType,
        season: this.param.season,
        projectTypes: this.param.natManageUnit
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          // 表格 数据渲染
          this.datas = [];
          for (let i in res.data.list) {
            let result = res.data.list[i];
            this.datas.push({
              key: parseInt(i) + 1,
              name: result.adName,
              allpopulation: result.sampleNum,
              allnum: result.badSampleNum,
              percent1: result.badSamplePow,
              num1: result.coverPeo,
              num2: result.badCoverPeo,
              num3: result.badPeoPow,
              num4: result.coverPla,
              num5: result.badCoverPla,
              num6: result.badPlaPow
            });
          }
        }
      });
    },
    // 水质超标统计
    getAreaBadDetects() {
      getAreaBadDetectTable({
        areaIds: this.param.areaId,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleType,
        season: this.param.season,
        projectTypes: this.param.natManageUnit
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          // 表格 数据渲染
          this.datas1 = [];
          for (let i in res.data.list) {
            let result = res.data.list[i];
            this.datas1.push({
              key: parseInt(i) + 1,
              name: result.adName,
              allpopulation: result.detectName,
              allnum: result.badCoverPla,
              percent1: result.badPlaPow,
              num1: result.badCoverPeo,
              num2: result.badPeoPow,
              num3: result.badSampleNum,
              num4: result.badSamplePow,
              num5: result.maxValue,
              num6: result.maxAdName,
              num7: result.maxProjectName,
              num8: result.minValue,
              num9: result.minAdName,
              num10: result.minProjectName
            });
          }
        }
      });
    },
    handleChangeArea(value) {
      this.param.areaId = value;
    },
    handleChangeYear(value) {
      this.param.reportYear = value;
    },
    handleChangeSampleType(value) {
      this.param.sampleType = value;
    },
    handleChangeSeason(value) {
      this.param.season = value;
    },
    handleChangeNatManageUnit(value) {
      this.param.natManageUnit = value;
    }
  }
};
</script>

<style scoped lang="less">
#ztfx /deep/ .ant-table-body {
  overflow-x: hidden !important;
}
.industry-second {
  width: 100%;
  .industry-second-up {
    padding: 1px 20px 0px;
    box-sizing: border-box;
    background: #ffffff;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    justify-content: space-between;
    .industry-second-ud-l {
      display: flex;
    }
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      margin-right: 30px;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.more {
  cursor: pointer;
}
.ant-advanced-search-form-top {
  width: 100%;
  // height: 86px;
  padding: 25px 0px;
  margin: 0px !important;
  display: flex !important;
  align-items: center;
}

.industry-second-down {
  width: 100%;
  padding: 1px 20px 20px;
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 20px;
}
.industry-second-down-top {
  padding: 20px 0px;

  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.industry-second-down-top-left {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  .line {
    width: 4px;
    height: 16px;
    background: #1890ff;
    border-radius: 2px;
  }
  .title {
    width: 120px;
    margin-left: 8px;
  }
}
.detail-top-right-left {
  margin-right: 21px;
}
.industry-second-ud {
  display: flex;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #e1e1e1;
  .industry-second-udone {
    position: relative;
    cursor: pointer;
    width: 105px;
    text-align: center;
    div {
      width: 50px;
      height: 1px;
      background: #1890ff;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      bottom: -4px;
      display: none;
    }
  }
  .progress-tabone-select {
    color: #1890ff;
    div {
      display: block;
    }
  }
}
</style>
